import React, { useState, useEffect } from "react";
import {connect} from 'react-redux';
import * as operacionesActions from '../../actions/operacionesActions';
import * as ubicacionesActions from '../../actions/ubicacionesActions';
import * as categoriasActions from '../../actions/categoriasActions';
import * as propiedadesActions from '../../actions/propiedadesActions';
import * as barriosActions from '../../actions/barriosActions';
import * as partidosActions from '../../actions/partidosActions';
import "./Filtro.css";

const {getCategorias} = categoriasActions;
const {getOperaciones} = operacionesActions;
const {getUbicaciones} = ubicacionesActions;
const {getBarrios} = barriosActions;
const {aplicarFiltros} = propiedadesActions;
const {getPartidos} = partidosActions;

const Filtro = (props) => {
  const {operacionesReducer:{operaciones},categoriasReducer:{categorias},ubicacionesReducer:{ubicaciones},barriosReducer:{barrios},partidosReducer:{partidos},propiedadesReducer:{filtros:filtrosReducer,filtrando}} = props;
  const [filtros, setFiltros] = useState({
    partido:'',
    localidades:[],
    barrios:[],
    categorias:[],
    operaciones:[],
    moneda:'dolar',
    minPrecio:'',
    maxPrecio:''
  });

  const [show, setShow] = useState(false);

  useEffect(() => {
    getData();
    setTimeout(() => {
      setShow(true);
    }, 1600);
  }, [])

  useEffect(() => {
    if(filtros.partido.length===0 && filtros.localidades.length===0 && filtros.barrios.length===0 && filtros.categorias.length===0 && filtros.operaciones.length===0 && filtros.minPrecio==="" && filtros.maxPrecio===""){
      return;
    }
    props.aplicarFiltros(filtros);
  }, [filtros]);


  const getData = async () => {
    if(operaciones.length===0){
      await props.getOperaciones();
    }
    if(categorias.length===0){
      await props.getCategorias();
    }
    if(partidos.length===0){
      await props.getPartidos();
    }
    if(ubicaciones.length===0){
      await props.getUbicaciones();
    }
    if(barrios.length===0){
      await props.getBarrios();
    }
  }

  const handleChangePartido = e =>{
    let sublistas = [...document.querySelectorAll(".sublista")];
    sublistas.forEach(lista => {
      if(!lista.classList.contains('d-none')) lista.classList.add('d-none');
    });
    document.getElementById(`ciudad-${e.target.id}`).classList.remove('d-none');
    return setFiltros({
      ...filtros,
      localidades:[],
      barrios:[],
      [e.target.name]:e.target.value
    });
  }
  
  //Manejo de evento change para cambiar valor de state de los formularios
  const handleChange = e =>{
    if(e.target.name==="localidades"){
      document.getElementById(`barrio-${e.target.id}`).classList.toggle('d-none');
    }

    //cambia el change cuando es moneda o rango de precios
    if(e.target.name === "moneda" || e.target.name === "minPrecio" || e.target.name === "maxPrecio"){
      return setFiltros({
        ...filtros,
        [e.target.name]:e.target.value
      })
    }

    if(filtros[e.target.name].includes(e.target.value)){
      setFiltros({
        ...filtros,
        [e.target.name]:filtros[e.target.name].filter(item=>item !== e.target.value),
        barrios:e.target.name==="ciudades" ? [] : [...filtros.barrios] //el change de ciudades, limpia los barrios seleccionados
      });
    }else{
      setFiltros({
        ...filtros,
        [e.target.name]:[...filtros[e.target.name],e.target.value]
      });
    }
  }

  return (
    <div className={show ? 'show filtros__propiedades' : 'filtros__propiedades'}>
      <button type="button" onClick={()=>setShow(!show)} className={show ? "btn btn-info hide" : "btn btn-info show"} id="btn-open-filters"><i className="fas fa-arrow-left"></i></button>
      <button type="button" onClick={()=>setShow(!show)} className="ml-auto btn btn-danger d-none" id="btn-close-filters">
        <i className="fas fa-times"></i>
      </button>
      {/* <section className="seccion__filtro filtros__aplicados">
        <h3>Filtros Aplicados</h3>
        <div className="items">
          <span className="filtroAplicado">
            Venta <i className=" ml-4 mr-2 fas fa-times"></i>
          </span>
          <span className="filtroAplicado">
            Pilar <i className=" ml-4 mr-2 fas fa-times"></i>
          </span>
        </div>
      </section> */}
      <section className="seccion__filtro">
        <h3>Operación</h3>
        <ul>
          {operaciones.map(op=>(
            <li key={op.id}>
              <input type="checkbox" name="operaciones" value={op.id} onChange={handleChange}/>
              {op.operacion}
            </li>
          ))}
        </ul>
      </section>
      <section className="seccion__filtro">
        <h3>Tipo de propiedad</h3>
        <ul>
          {categorias.map(cat=>(
            <li key={cat.id}>
              <input type="checkbox" name="categorias" value={cat.id} onChange={handleChange}/>
              {cat.categoria}
            </li>
          ))}
        </ul>
      </section>
      <section className="seccion__filtro" id="seccion_ubicaciones">
        <h3>Ubicaciones</h3>
        <ul>
          {partidos.map(p=>(
            <li key={p.id}>
              <input type="radio" name="partido" onChange={handleChangePartido} id={p.id} value={p.id}/>
              {p.partido}
              <ul id={`ciudad-${p.id}`} className="d-none sublista">
                {ubicaciones.map(ub=>(
                  ub.idPartido===p.id ?
                  <li key={ub.id}>
                    <input type="checkbox" name="localidades" onChange={handleChange} id={ub.id} value={ub.id}/>
                    {ub.localidad}

                    <ul id={`barrio-${ub.id}`} className="d-none sub_sublista">
                      {barrios.map(bar=>(
                        bar.idLocalidad === ub.id ?
                        <li key={bar.idBarrio}>
                          <input type="checkbox" name="barrios" id={bar.idBarrio} value={bar.idBarrio} onChange={handleChange} checked={filtros.barrios.includes(`${bar.idBarrio}`)}/>
                          {bar.barrio}
                        </li> : null
                      ))}
                    </ul>
                  </li>:null
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </section>
      <section className="seccion__filtro">
        <h3>Precio</h3>
        <div className="d-flex">
          <div className="pt-2">
            <input type="radio" name="moneda" value="pesos" onChange={handleChange} checked={filtros.moneda==="pesos"}/> Pesos
            <input
              type="text"
              name="minPrecio"
              className="form-control mt-2"
              placeholder="Desde"
              value={filtros.minPrecio}
              onChange={handleChange}
            />
          </div>
          <div className="pt-2">
            <input type="radio" name="moneda" value="dolar" onChange={handleChange} checked={filtros.moneda==="dolar"}/> Dolares
            <input
              type="text"
              name="maxPrecio"
              className="form-control mt-2"
              placeholder="Hasta"
              value={filtros.maxPrecio}
              onChange={handleChange}
            />
          </div>
        </div>
        <button type="button" className="btn btn-info mt-3 btn-block">
          Buscar
        </button>
      </section>
    </div>
  );
};

const mapStateToProps = ({categoriasReducer,operacionesReducer,ubicacionesReducer,propiedadesReducer,barriosReducer,partidosReducer})=>{
  return {categoriasReducer,operacionesReducer,ubicacionesReducer,propiedadesReducer,barriosReducer,partidosReducer}
};

const mapDispatchToProps = {
  getCategorias,
  getOperaciones,
  getUbicaciones,
  getBarrios,
  aplicarFiltros,
  getPartidos
};
export default connect(mapStateToProps,mapDispatchToProps)(Filtro);
