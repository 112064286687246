import {LOADING,ERROR,OBTENER_PARTIDOS} from '../types/partidos';
import {API} from '../config';

export const getPartidos = ()=>async(dispatch)=>{
    dispatch({
        type:LOADING
    });
    try {
        const reqPartidos = await fetch(`${API}/partidos`);
        const dataPartidos = await reqPartidos.json();
        return dispatch({
            type:OBTENER_PARTIDOS,
            payload:dataPartidos.partidos
        })
    } catch (error) {
        dispatch({
            type:ERROR,
            payload:error.message
        })
    }
}